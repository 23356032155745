import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { ProjectAccessLevel } from "@components/project/project-access-level";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { SdbProject } from "@custom-types/project-types";
import { Skeleton } from "@mui/material";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { useAppDispatch } from "@store/store-helper";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { ChangeProjectDetailsEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DEFAULT_PROJECT_AREA, getProjectArea } from "@utils/project-utils";
import { DASH } from "@utils/ui-utils";
import { useMemo } from "react";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";

interface Props {
  /** Whether the project is editable or not */
  isProjectEditable: boolean;

  /** The project to display in the ProjectOverview */
  project?: SdbProject | null;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/** Default maximum rows for the address text field. */
const ADDRESS_DEFAULT_MAX_ROWS = 5;

/**
 * The number of rows for the skeleton.
 * It should match the number of rows in the component, currently we have client, address, access level and area.
 */
const SKELETON_ROWS = 4;

/**
 * Shows the secondary details for a project. These include the client name, address and area.
 * They are displayed below the primary details of the project.
 */
export function SecondaryProjectDetails({
  isProjectEditable,
  project,
  isLoading,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { canChangeProjectAccessLevel } = useHasUserValidRoleProjectLevel();
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();

  /** Only show the project area if the user typed something different than the default one. */
  const shouldShowProjectArea: boolean = useMemo(() => {
    return !!project && project.area?.amount !== DEFAULT_PROJECT_AREA;
  }, [project]);

  /**
   * Updates the project details in the backend and returns the new value if successful.
   * Otherwise, returns the old value.
   *
   * @param value The new value to update the project details with.
   * @param field The field to update.
   */
  async function onUpdateProjectDetail(
    value: string,
    field: "address" | "clientName"
  ): Promise<string> {
    if (!project) {
      return "";
    }
    try {
      await dispatch(
        updateProjectDetails({
          coreApiClient,
          payload: { [field]: value },
        })
      );
      return value;
    } catch (error) {
      handleErrorWithToast({
        id: `changeProjectDetails-${field}-${Date.now().toString()}`,
        title: `Could not change ${field}`,
        error,
      });
      return project[field] ?? "";
    }
  }

  if (isLoading || !project) {
    return (
      <>
        {Array.from({ length: SKELETON_ROWS }).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height="48px"
            sx={{ marginTop: "30px" }}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <FaroTextField
        label="Client"
        initialValue={project.clientName ?? ""}
        defaultEmptyValue={DASH}
        sx={{ mb: "30px" }}
        isReadOnly={!isProjectEditable}
        onConfirmed={(value) => {
          trackEvent({
            name: ChangeProjectDetailsEvents.changeProjectClient,
          });

          return onUpdateProjectDetail(value, "clientName");
        }}
      />

      <FaroTextField
        label="Location"
        initialValue={project.address}
        defaultEmptyValue={DASH}
        sx={{ mb: "30px" }}
        isReadOnly={!isProjectEditable}
        isMultiline={true}
        maxRows={ADDRESS_DEFAULT_MAX_ROWS}
        fontSize={DEFAULT_INPUT_FONT_SIZE}
        onConfirmed={(value) => {
          trackEvent({
            name: ChangeProjectDetailsEvents.changeProjectAddress,
          });

          return onUpdateProjectDetail(value, "address");
        }}
      />

      <ProjectAccessLevel
        isAccessLevelEditable={canChangeProjectAccessLevel}
        project={project}
        sx={{ mb: "30px" }}
      />

      {shouldShowProjectArea && (
        <FaroTextField
          label="Area"
          initialValue={getProjectArea(project)}
          sx={{ mb: "30px" }}
        />
      )}
    </>
  );
}
